import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';
import Chip from '@kyruus/chip';

export const AvailabilityControlsContainer = styled.section`
  margin-bottom: ${fromTheme('spacing_medium')};
  font-family: ${fromTheme('font_face')};
  background: ${fromTheme('color_background_secondary')};
  padding: ${fromTheme('spacing_medium')};
  border: 1px solid;
  border-color: ${fromTheme('color_border_disabled')};
  width: 100%;
  border-radius: ${fromTheme('spacing_small')};
  @media (min-width: ${fromTheme('screen_medium')}) {
    display: flex;
  }
  ${(props) => {
    return props.isOn ? 'flex-direction: column' : 'align-items: center;';
  }}
`;

export const AvailabilityControlsHeadingOff = styled.h3`
  @media (min-width: ${fromTheme('screen_medium')}) {
    margin-left: auto;
  }
  @media (max-width: ${fromTheme('screen_medium')}) {
    margin-top: ${fromTheme('spacing_medium')};
  }
`;

export const AvailabilityControlsHeadingOn = styled.h3`
  margin-bottom: ${fromTheme('spacing_medium')};
  font-weight: ${fromTheme('font_weight_bold')};
`;

export const ButtonsContainer = styled.div`
  button:not(:last-child) {
    margin-right: ${fromTheme('spacing_small')};
  }

  @media (min-width: ${fromTheme('screen_medium')}) {
    display: inline-block;
  }
`;

export const StyledChip = styled(Chip)`
  background-color: ${fromTheme('color_background')};
  border: ${fromTheme('border_width')} ${fromTheme('border_style')}
    ${fromTheme('color_border')};
  color: ${fromTheme('color_text')};
`;

export const SelectedAppointmentContainer = styled.div`
  div:not(:last-child) {
    margin-right: ${fromTheme('spacing_small')};
  }
  @media (max-width: ${fromTheme('screen_medium')}) {
    margin-bottom: ${fromTheme('spacing_medium')};
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${fromTheme('screen_medium')}) {
    flex-direction: column;
  }
`;
