import React from 'react';
import { AccordionBanner } from '@kyruus/banner';
import { RichTextRenderer } from '@kyruus/rich-text/Renderer';
import { isSearchAlertsEnabled } from 'Common/config';

export const SearchAlerts = ({ config, searchAlerts }) => {
  if (!isSearchAlertsEnabled(config) || searchAlerts?.total <= 0) return null;
  const initialExpanded = Boolean(config?.search_alerts?.default_expanded);
  return (
    searchAlerts?.alerts.map(({ id, responses = [] }) => {
      const headerText =
        responses.find(({ message_name }) => message_name === 'body')
          ?.message ?? null;
      const bodyText =
        responses.find(({ message_name }) => message_name === 'detail')
          ?.message ?? null;
      return (
        <AccordionBanner
          key={id}
          id={`provider-search-alerts-${id}`}
          icon="!"
          type="alert"
          headerText={headerText}
          initialExpanded={initialExpanded}
        >
          <RichTextRenderer
            id={`provider-search-alert-${id}-content`}
            content={bodyText}
          />
        </AccordionBanner>
      );
    }) ?? null
  );
};

export const V9SearchAlerts = ({ config, searchAlerts }) => {
  if (!isSearchAlertsEnabled(config) || !searchAlerts?.length) return null;
  const initialExpanded = Boolean(config?.search_alerts?.default_expanded);
  return (
    searchAlerts?.map(({ id, message }) => {
      const headerText = message.body ?? null;
      const bodyText = message.detail ?? null;
      return (
        <AccordionBanner
          key={id}
          id={`provider-search-alerts-${id}`}
          icon="!"
          type="alert"
          headerText={headerText}
          initialExpanded={initialExpanded}
        >
          <RichTextRenderer
            id={`provider-search-alert-${id}-content`}
            content={bodyText}
          />
        </AccordionBanner>
      );
    }) ?? null
  );
};
