import { defineMessages } from '@kyruus/intl';

export default defineMessages({
  showGlobalAvailabilityBtnText: {
    id: 'button.global_availability.show',
    description:
      'Text to display in button used to toggle ON global availability',
    defaultMessage: 'Show Available Appointments'
  },
  showGlobalAvailabilityBtnTextAriaLabel: {
    id: 'button.global_availability.show_aria_label',
    description:
      'Text to be read by screen reader in toggle button ON global availability',
    defaultMessage:
      'Turn on to see available appointment times across providers'
  },
  hideGlobalAvailabilityBtnText: {
    id: 'button.global_availability.hide',
    description:
      'Text to display in button used to toggle OFF global availability',
    defaultMessage: 'Turn Off'
  },
  hideGlobalAvailabilityBtnTextAriaLabel: {
    id: 'button.global_availability.hide_aria_label',
    description:
      'Text to be read by screen reader in toggle button OFF global availability',
    defaultMessage: 'Turn off available appointment times across providers'
  },
  editGlobalAvailabilityBtnText: {
    id: 'button.global_availability.edit',
    description:
      'Text to display in button used to edit global availability selections',
    defaultMessage: 'Edit'
  },
  editGlobalAvailabilityBtnTextAriaLabel: {
    id: 'button.global_availability.edit_aria_label',
    description:
      'Text to be read by screen reader on button used to edit global availability selections',
    defaultMessage: 'Edit your appointment information'
  },
  globalAvailControlsHeaderTextOff: {
    id: 'global_availability.controls.off.header',
    description:
      'Guide text for the availability controls in their OFF state. Rendered as a section header.',
    defaultMessage:
      'Turn on to see available appointment times across providers.'
  },
  globalAvailControlsHeaderTextOn: {
    id: 'global_availability.controls.on.header',
    description:
      'Guide text to display when in their ON state. Rendered as a section header.',
    defaultMessage: 'Available Appointment Times'
  },
  patientRelBtnTextNew: {
    id: 'global_availability.controls.patient_rel.new',
    defaultMessage: 'A New Provider',
    description:
      'Text to display in the chip rendered when a patient has toggled on availabilities for providers with whom they will have a NEW relationship'
  },
  patientRelBtnTextEstablished: {
    id: 'global_availability.controls.patient_rel.established',
    defaultMessage: 'Your Current Doctor',
    description:
      'Text to display in the chip rendered when a patient has toggled on availabilities for providers with whom they have an ESTABLISHED relationship'
  }
});
